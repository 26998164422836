<template>
  <div class="queryDetails">
    <!-- 简易订餐 -->
    <van-nav-bar
      :title="'扫码就餐'"
      left-text
      left-arrow
      @click-left="onClickLeft"
    />
    <!--header结束-->
    <!--content开始-->
    <div class="overflow_div">
      <div class="msg" v-if="s == 1">
        <img src="@/assets/img/success.png" class="dimg" />
        <div class="dh2">就餐扫码成功！</div>
      </div>
      <div class="msg" v-else-if="s == 2">
        <img src="@/assets/img/error.png" class="dimg" />
        <div class="dh2 red">{{ info }}</div>
      </div>
    </div>
    <!--content结束-->
  </div>
</template>

<script>
import { processWxScanMeal } from '@api/wx.js'
export default {
  data() {
    const devid = this.$route.query.devid
    const ts = this.$route.query.ts
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      devid,
      ts,
      userInfo,
      s: 0,
      info: '就餐扫码异常！'
    }
  },
  mounted() {
    this._processWxScanMeal()
  },
  methods: {
    // 返回
    onClickLeft() {
      this.$router.push('/home')
    },
    _processWxScanMeal() {
      processWxScanMeal({
        devid: this.devid,
        empcode: this.userInfo.empcode,
        barcodetimestamp: this.ts
      }).then(res => {
        if (res.data[0].info) {
          this.s = 2
          this.info = res.data[0].info
        } else {
          this.s = 1
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.queryDetails {
  height: 100vh;
  overflow: hidden;
  // background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    background: #f6f6f6;
    // overflow: auto;
    height: calc(100% - 100px);
    .msg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      .dh2 {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }
      .dimg {
        width: 50%;
        display: block;
        margin: 30px auto 10px;
      }

      .red {
        color: #ff0000;
      }
    }
  }
}
</style>
